@use '@angular/material' as mat;
@import '../../styles-variables';

@mixin lynkd-pattern-app-component-theme($theme) {
  $primary: map-get($theme, primary);

  .theme-wrapper {
    background: mat.get-color-from-palette($primary, 400);
  }

}
