@use '@angular/material' as mat;
@import '../../../../styles-variables';

@mixin lynkd-pattern-dashboard-component-theme($theme) {
  $primary: map-get($theme, primary);
  $accent: map-get($theme, accent);
  $warn: map-get($theme, warn);
  $background: map-get($theme, background);

  ::-webkit-scrollbar-thumb {
    -webkit-box-shadow: inset 0 0 27px mat.get-color-from-palette($primary, 600);
  }

  ::ng-deep .cdk-global-overlay-wrapper {
    &::-webkit-scrollbar-thumb {
      -webkit-box-shadow: none;
    }
  }

  ::placeholder {
    color: mat.get-color-from-palette($primary, 600);
  }

  .primary {
    color: mat.get-color-from-palette($accent);
  }

  p {
    color: mat.get-color-from-palette($primary, 500);

    a {
      border-bottom: 2px solid mat.get-color-from-palette($accent);
    }
  }

  .mat-flat-button {
    color: white !important;

    &.mat-accent {
      color: mat.get-color-from-palette($accent) !important;

      &:hover {
        background-color: mat.get-color-from-palette($accent, 200);
      }
    }
  }

  .mat-chip {
    &.mat-standard-chip {
      background-color: mat.get-color-from-palette($accent, 200);
      color: white;
    }
  }

  .mat-select {
    .mat-select-value {
      color: mat.get-color-from-palette($primary, 500) !important;
    }
  }

  .mat-option {
    color: mat.get-color-from-palette($primary, 500) !important;

    &.order-details {
      color: mat.get-color-from-palette($primary, 500) !important;

      &.delivered {
        color: mat.get-color-from-palette($primary, 200) !important;
      }
    }
  }

  .product-view {
    .button-stroked {
      border-color: mat.get-color-from-palette($primary, 200) !important;
    }

    .product-title {
      h2 {
      }

      h3 {
        color: mat.get-color-from-palette($primary, 600);
      }

      button {
        background: mat.get-color-from-palette($accent) !important;
        color: white !important;

        &:hover {
          background: mat.get-color-from-palette($accent) !important;
        }

        &:last-of-type {
          background-color: mat.get-color-from-palette($accent, 200) !important;
          color: white;

          &:hover {
            background: mat.get-color-from-palette($accent) !important;
          }
        }
      }
    }

    .product-images {
      gallery {
        background-color: transparent;

        gallery-thumbs {
          gallery-thumb {
            border: solid 2px mat.get-color-from-palette($primary, 600);
            background: white;
          }
        }

        .g-box {
          border: solid 2px mat.get-color-from-palette($primary, 600);
        }
      }

      .thumbs-nav,
      gallery-nav {
        i {
          svg {
            * {
              fill: black;
            }
          }

          &:hover {
            svg {
              * {
                fill: mat.get-color-from-palette($primary, 600);
              }
            }
          }
        }
      }
    }

    .product-comments {
      li {
        border-top: 1px solid mat.get-color-from-palette($primary, 300);

        div {
          margin-bottom: 10px;

          p {
            color: mat.get-color-from-palette($primary, 600);
            line-height: 1;
          }

          strong {
            color: mat.get-color-from-palette($primary, 400);
          }
        }
      }

      mat-form-field {
        textarea {
          background-color: mat.get-color-from-palette($primary, 300);
        }
      }
    }

    .product-key-metrics {
      .row {
        .col-4 {
          div {
            border-color: mat.get-color-from-palette($accent) !important;
          }
        }
      }
    }



    .product-product-details {
      .mat-tab-group {
        .mat-tab-header {
          .mat-tab-labels {
            .mat-tab-label {
              border-color: mat.get-color-from-palette($accent, 200) !important;
              color: mat.get-color-from-palette($accent, 200) !important;

              .mat-tab-label-content {
              }

              &.mat-tab-label-active {
                background: mat.get-color-from-palette($accent, 200) !important;
                color: white !important;

                .mat-tab-label-content {
                }
              }
            }
          }
        }
      }

      .mat-table {
        overflow-x: scroll;
        td.mat-cell {
          border-bottom-color: mat.get-color-from-palette($primary, 300);
        }
      }
    }

    .product-performance-details {
      .mat-table {
        border: solid 1px mat.get-color-from-palette($primary, 600);
        width: 100%;
        overflow-x: scroll;
        .mat-sort-header
          .mat-header-cell
          .cdk-header-cell
          .cdk-drag
          .text-capitalize
          .ng-tns-c189-76
          .cdk-column-week
          .mat-column-week
          .ng-star-inserted {
          background-color: blue;
        }

        .mat-sort-header-container {
          display: flex;
          cursor: pointer;
          align-items: center;
          letter-spacing: normal;
          outline: 0;
          text-align: center;
          flex-direction: row;
          justify-content: center;
        }

        .mat-column-Week {
          background-color: blue;
        }

        tr {
          .mat-header-cell {
            white-space: nowrap;
            background-color: mat.get-color-from-palette($accent);

            text-align: center;
            color: white;
          }

          td.mat-cell {
            border-bottom: 1px solid mat.get-color-from-palette($primary, 600);
            border-right: 1px solid mat.get-color-from-palette($primary, 600);
            background-color: mat.get-color-from-palette($primary, 300);
            color: mat.get-color-from-palette($primary, 600);
            text-align: center;

            &:first-child {
              border-bottom: 1px solid mat.get-color-from-palette($primary, 300);
              border-right: 1px solid mat.get-color-from-palette($primary, 300);
              background-color: mat.get-color-from-palette($primary, 600);
              // min-width: 100px;
              white-space: nowrap;
              color: white;
            }

            &:last-child {
              border-right: 0;
            }
          }

          &:last-child {
            td.mat-cell {
              border-bottom: 0;
            }
          }
        }
      }

      .product-view-tab-data {
      }

      .product-view-tab-graph {
      }
    }
  }

  .cdk-overlay-container {
    .product-view-dialog {
      .mat-dialog-container {
        background-color: mat.get-color-from-palette($primary, 300);
      }
    }
  }

  .mat-dialog-title {
    color: mat.get-color-from-palette($primary, 400);
  }

  .mat-dialog-container {
    background-color: white;
    box-shadow: 0 2px 20px 0 rgba(black, 0.25);

    .mat-list-base {
      border-color: mat.get-color-from-palette($primary, 200) !important;

      .mat-list-item {
        .mat-pseudo-checkbox {
          &:after {
            color: white;
          }
        }
      }

      &.no-checkbox {
        .mat-list-item {
          &[aria-selected='true'] {
            background-color: mat.get-color-from-palette($accent, 500);
            color: white;
          }
        }
      }
    }

    .metric-range {
      li {
        .ng2-nouislider {
          .noUi-horizontal {
            background: rgba(mat.get-color-from-palette($accent, 500), 0.5);

            .noUi-handle {
              box-shadow: 0 2px 5px -3px rgba(0, 0, 0, 0.35);
              background: mat.get-color-from-palette($accent, 500) !important;
            }
          }

          .noUi-connect {
            background: mat.get-color-from-palette($accent, 500);
          }

          &[ng-reflect-disabled='true'] {
            .noUi-horizontal {
              background: mat.get-color-from-palette($primary, 300) !important;
            }
          }
        }

        input {
          border-color: mat.get-color-from-palette($primary, 700) !important;
          background: transparent;

          &[disabled='true'] {
            border-color: transparent !important;
            color: mat.get-color-from-palette($primary, 300) !important;
          }
        }
      }
    }
  }

  .mat-select-panel {
    background-color: white;
    box-shadow: 0 2px 8px -2px rgba(black, 0.5);

    .mat-option {
      &:hover {
        background-color: mat.get-color-from-palette($primary, 300);
      }

      .mat-pseudo-checkbox {
        border-color: mat.get-color-from-palette($primary, 600) !important;

        &.mat-pseudo-checkbox-checked {
          border-color: transparent !important;
          background: mat.get-color-from-palette($accent);

          &:after {
            color: white;
          }
        }
      }
    }
  }

  .mat-stroked-button {
    box-shadow: 0 2px 10px -6px rgba(0, 0, 0, 0.25) !important;

    &.mat-accent:not([disabled]) {
      border-color: mat.get-color-from-palette($accent) !important;
      background: white;
    }
  }

  .button-stroked {
    &.white {
      border-color: mat.get-color-from-palette($accent) !important;
      background-color: white;
      color: mat.get-color-from-palette($accent, 500) !important;

      .mat-form-field-label {
        color: mat.get-color-from-palette($accent, 500);
      }

      .mat-select-value-text {
        color: mat.get-color-from-palette($accent, 500) !important;
      }
    }
  }

  .mat-checkbox {
    .mat-checkbox-checkmark-path {
      stroke: white !important;
    }

    .mat-checkbox-label {
      color: #666666;
    }
  }

  .mat-autocomplete-panel {
    .mat-option {
      color: mat.get-color-from-palette($primary, 500);
    }
  }

  .calendar-wrapper {
    border-color: mat.get-color-from-palette($primary, 200) !important;

    .md-drppicker {
      .calendar {
        &.left {
          border-right-color: mat.get-color-from-palette($primary, 200) !important;
        }
      }

      td.active,
      .md-drppicker td.active:hover {
        background-color: mat.get-color-from-palette($accent) !important;
        color: white;
      }

      td.in-range {
        background: rgba(mat.get-color-from-palette($accent), 0.2);
      }
    }

    /* .mat-calendar {

      &:first-child {
        border-right-color: mat-color($primary, 200) !important;
      }

      .mat-button {
        color: mat-color($primary, 500);
      }

      .mat-calendar-table-header {

        th {
          color: mat-color($primary, 500);
        }

      }

      .mat-calendar-body {
        color: mat-color($primary, 500);

        .mat-calendar-body-selected {
          background: mat-color($accent);
          color: white;
        }

        .mat-calendar-body-today {

          &.mat-calendar-body-selected {
            box-shadow: none;
          }

        }

        .date-selected {
          background: mat-color($accent);
          color: white;
        }

        .time-range {

          .mat-calendar-body-cell-content {
            background: rgba(mat-color($accent),0.2);
          }

        }

      }

    } */
  }

  .mat-snack-bar-container {
    box-shadow: 0 2px 20px 0 rgba(black, 0.25);
    background-color: black;

    .mat-simple-snackbar {
      color: mat.get-color-from-palette($primary, 300);
    }
  }

  mat-toolbar {
    a {
      color: white;
    }
  }

  mat-sidenav-container {
    &.dashboard {
      background: mat.get-color-from-palette($primary, 300) !important;
    }

    mat-sidenav {
      background: mat.get-color-from-palette($primary, 400) !important;
      box-shadow: 0 2px 10px 0 rgba(0, 0, 0, 0.25) !important;

      .mat-sidebar-toggle {
        border-left: 1px solid mat.get-color-from-palette($accent, 300) !important;

        svg {
          path {
            fill: white !important;
          }
        }
      }
    }

    mat-accordion {


      &.accordion-filters {

        .mat-nav-list {
          background-color: mat.get-color-from-palette($accent, 200) !important;

          .mat-list-item {
            background-color: transparent !important;
            color: mat.get-color-from-palette($primary, 500) !important;

            mat-form-field {
              .mat-form-field-label {
                color: mat.get-color-from-palette($primary, 600) !important;
              }

              .mat-select-value {
                color: white !important;
              }

              .mat-form-field-underline {
                background-color: mat.get-color-from-palette($primary, 700) !important;
              }
            }

            .mat-flat-button {
              background-color: transparent !important;
            }
          }
        }
      }
    }

    .mat-drawer-backdrop {
      background: transparent !important;
    }
  }

  .error-notification-overlay {
    color: mat.get-color-from-palette($warn, default-contrast);
    background-color: mat.get-color-from-palette($warn);
  }

  .info-notification-overlay {
    color: mat.get-color-from-palette($accent, default-contrast);
    background-color: mat.get-color-from-palette($accent);
  }

  .success-notification-overlay {
    color: mat.get-color-from-palette($success-colors, default-contrast);
    background-color: mat.get-color-from-palette($success-colors);
  }

  .warning-notification-overlay {
    color: mat.get-color-from-palette($warning-colors, default-contrast);
    background-color: mat.get-color-from-palette($warning-colors);
  }

  .card {
    box-shadow: 0 3px 16px -8px rgba(black, 0.35);
    background-color: white;
  }
}
