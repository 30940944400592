
// Custom Theming for NG-ZORRO
// For more information: https://ng.ant.design/docs/customize-theme/en
//@import "../node_modules/ng-zorro-antd/ng-zorro-antd.less";
@import "../../../node_modules/ng-zorro-antd/ng-zorro-antd.less";

// Override less variables to here
// View all variables: https://github.com/NG-ZORRO/ng-zorro-antd/blob/master/components/style/themes/default.less

// @primary-color: #1890ff;
@primary-color: rgb(51, 51, 51);
@font-family: "Montserrat", sans-serif;


body{
    font-family: "Montserrat", sans-serif;
}
